import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/appContext.js";
import SharedWrapper from "../assets/wrappers/SharedLayout.js";
import { LargeLogo } from "../components";
import { ReportsOptionData } from "../components/Reports";
import { Display, Display1, StackedDisplay } from "../components/dashboard";

// http://localhost:3000/reports/1709
const Reports = () => {
  const {
    job,
    address,
    customer,
    contacts,
    options,
    files,
    photos,
    user,
    loadMainRecords,
    getLists,
    defaults,
    getDefaults,
    getArrayRecord,
    getPriceAdjustments,
    getReportWarranties,
    handleChange,
  } = useAppContext();

  const { street_address, city, state, zip } = address;
  const contact = contacts.filter(
    (contact) => contact.id.toString() === job.default_contact.toString()
  )[0];
  const { jobId } = useParams();

  const [reportOptions, setReportOptions] = useState();
  const [beforePhotos, setBeforePhotos] = useState();
  const [afterPhotos, setAfterPhotos] = useState();

  // grab url parameters
  let url_string = window.location.href;
  const url = new URL(url_string);
  const reportName = url.searchParams.get("reportName");
  const userMode = url.searchParams.get("userMode");
  // build date variable with timezone offset accounted for
  const date = new Date();
  const baseDate = new Date(url.searchParams.get("reportDate"));
  const timeZoneOffset = baseDate.getTimezoneOffset() * 60 * 1000;
  const adjustedTimestamp = Date.parse(baseDate) + timeZoneOffset;
  const reportDate = new Date(adjustedTimestamp);
  const dateFormat = {
    weekday: undefined,
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const initialize = async (jobId) => {
    // --- load lists and set default values first ---
    if (Object.keys(defaults).length === 0) {
      console.log("ran once...");
      await getDefaults();
      await loadMainRecords(jobId);
      await getLists();
    }
  };

  const getOptionData = async () => {
    console.log("attempting to build report options array...");
    let rprtOptions = [...options];
    // - remove any extra options for invoices and receipts
    if (!reportName.includes("Proposal")) {
      const chosenOption = job.options_id
        ? // grab the selected option for the job if it exists
          options.filter((option) => option.id === job.options_id)[0]
        : // if no job selected then grab the first option
          options[0];
      rprtOptions = [{ ...chosenOption }];
    }
    console.log("reportOptions2:", rprtOptions);
    setReportOptions(
      await Promise.all(
        rprtOptions.map(async (option) => {
          const { fees, discounts } = await getPriceAdjustments(
            `/price-adj/${option.id}`
          );
          const warranties = await getReportWarranties(
            `/warranties/${option.id}`
          );
          const jobItems = await getArrayRecord(
            `/job-items/${option.id}`,
            "jobItems"
          );
          return {
            optn: option,
            jbItms: [...jobItems],
            wrrnts: [...warranties],
            dscnts: [...discounts],
            fs: [...fees],
            slsTx: option.sales_tax,
          };
        })
      )
    );
  };

  // First, determine user mode (live or sandbox)
  useEffect(() => {
    console.log("1st RUN");
    const baseObject = user;
    const name = "user.user_mode";
    const value = parseInt(userMode) || 2;
    handleChange({ baseObject, name, value });
    // change usermode in local storage
    const localUserData = JSON.parse(localStorage.getItem("user", user));
    localUserData.user_mode = value ? Number(value) : value;
    console.log("local userMode:", localUserData);
    localStorage.setItem("user", JSON.stringify(localUserData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Second, load the core data
  useEffect(() => {
    console.log("2nd RUN");
    if (user.user_mode > 0) {
      initialize(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user_mode]);

  // Finally, load option specific data
  useEffect(() => {
    if (options.length > 0) {
      console.log("FINAL RUN");
      getOptionData();
      const orderedPhotos = photos.sort((a, b) => a.width - b.width);
      setAfterPhotos(
        orderedPhotos.filter((photo) => photo.category === "After Photos")
      );
      setBeforePhotos(
        orderedPhotos.filter((photo) => photo.category === "Before Photos")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  function Photos({ photosList }) {
    return (
      <div className="before-after-photos">
        {photosList?.map((photo, i) => {
          const photoWidth = photo?.width;
          const photoHeight = photo?.height;
          let width, height, divClass;
          if (photoWidth / photoHeight >= 1) {
            width = 700;
            height = 525; // fit for page breaks
            divClass = "whole four-by-three-image";
          } else {
            width = 366; // fit to half page
            height = 488;
            divClass = "one-half";
          }
          const photoFile = files.filter(
            (file) => file.name === photo.photo_name
          )[0];
          const url = photoFile?.thumbnails[0]?.large.url;

          return (
            <div className={divClass} key={i}>
              <img
                src={url}
                key={i}
                className={"before-after-photo"}
                height={height}
                width={width}
                alt={`${photo.photo_name} thumbnail`}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <SharedWrapper>
      <main className="dashboard flex-parent report">
        {/* --- Header --- */}
        <div className="header whole flex-parent">
          <div className="one-half">
            <LargeLogo />
          </div>
          <div className="one-half">
            <div className="header-address">
              FreeLite Enterprises, Inc.
              <br />
              221 Garvon St
              <br />
              Garland, TX 75040
              <br />
              972-234-5990
            </div>
          </div>
          <div className="whole">
            <h1>{reportName}</h1>
          </div>
        </div>
        {/* --- Basic Info --- */}
        <div className="base-info flex-parent whole section">
          <div className="one-third">
            <Display name="Job #" value={job?.job_number} />
            <Display name="Name" value={customer?.customer_name} />
            <Display
              name="Date"
              value={reportDate.toLocaleDateString(undefined, dateFormat)}
            />
          </div>
          <div className="one-third">
            <Display1
              name="Address"
              value={`${street_address}\n${city}, ${state} ${zip}`}
            />
          </div>
          <div className="one-third">
            <Display name="Contact" value={contact?.contact_name || "N/A"} />
            <Display name="Email" value={contact?.email || "N/A"} />
            <Display name="Phone" value={contact?.phone || "N/A"} />
          </div>
        </div>
        {/* --- Work Description --- */}
        <div className="whole section">
          <h3>Work Description</h3>
          <Display1 value={job.work_description} />
        </div>
        {/* --- Option Specific Data (jobitems, fees, discounts, warranties) --- */}
        {reportOptions?.map((option, index) => {
          return (
            <div key={index} className="whole option">
              <h2>
                {reportOptions.length > 1 && // only add when more than one option
                  option.optn.title}
              </h2>
              <ReportsOptionData
                optionVars={option}
                optionsId={option.id}
                reportName={reportName}
              />
            </div>
          );
        })}
        {/* --- Expiration Statement --- */}
        {reportName.includes("Proposal") && (
          <div>
            *Quote expires in 30 days. Final payment due upon completion.
          </div>
        )}
        {/* --- Signature / Date --- */}
        <div className="whole section flex-parent footer">
          <div className="one-half">
            <StackedDisplay name="Head of Operations" value="Marie McDonald" />
          </div>
          <div className="one-half">
            <StackedDisplay name="Date" value={date.toLocaleDateString()} />
          </div>
        </div>
        {/* --- Before/After Photos --- */}

        {beforePhotos?.length > 0 && (
          <div className="whole section">
            <h3>Before Photos</h3>
            <Photos photosList={beforePhotos} />
          </div>
        )}
        {!reportName.includes("Proposal") && afterPhotos?.length > 0 && (
          <div className="whole section">
            <h3>After Photos</h3>
            <Photos photosList={afterPhotos} />
          </div>
        )}
      </main>
    </SharedWrapper>
  );
};

export default Reports;
