const appSave = async ({
  initialState,
  location,
  address,
  customer,
  contacts,
  job,
  defaults,
  options,
  warranties,
  fees,
  discounts,
  appointments,
  payments,
  indices,
  report,
  email,
  calendar,
  user,
  employees,
  createRecord,
  saveRecord,
  deleteRecord,
  handleChanges,
  handleArrayChange,
  handleArrayChanges,
  displayAlert,
  toggleReportForm,
  loadJobOptions,
}) => {
  const page = location.pathname;
  // --- Save Job Summary ---
  if (page === "/job-summary") {
    console.log("saving job summary...");
    // save customer
    console.log("saving customer...");
    if (!customer.id) {
      displayAlert("Please assign a customer for this job");
      return;
    }
    await saveRecord(`/customer/update/${customer.id}`, customer, "Customer");
    // save contacts
    console.log("saving contact...");
    contacts.forEach(async (contact, index) => {
      // create new contact if needed
      if (!contact.id) {
        const record = await createRecord(
          "/contact/create",
          contact,
          "Contact"
        );
        handleArrayChange({
          baseName: "contacts",
          baseArray: [...contacts],
          index,
          property: "id",
          value: record.id,
        });
      } else {
        // update existing contact
        await saveRecord(`/contact/update/${contact.id}`, contact, "Contact");
      }
    });
    // save address
    if (!address.id) {
      displayAlert("Address is missing an Id");
      return;
    }
    await saveRecord(`/address/update/${address.id}`, address, "Address");
    // save core info
    if (!job.id) {
      displayAlert("This job is missing an Id");
      return;
    }
    console.log("job.default_contact:", job.default_contact);
    if (!job.default_contact) {
      displayAlert("Please add a current contact for this job.");
      return;
    }
    saveRecord(`/core-job/update/${job.id}`, job, "job");
  }
  // --- Save Work Specs ---
  else if (page === "/work-specs") {
    console.log("saving work sepcs...");
    let newOption = {};
    // save core option
    const index = indices.optionsIndex;
    const allValuesPresent = options.every((option) => option.core_job_data_id);
    if (!allValuesPresent) {
      displayAlert("Job Id not found");
      return;
    }
    // save job with new work description
    await saveRecord(`/core-job/update/${job.id}`, job, "job");
    // create new option
    if (!options[index].id) {
      newOption = await createRecord(
        "/options/create",
        options[index],
        "option"
      );
      // or update old one
    } else {
      await saveRecord(
        `/options/update/${options[index].id}`,
        options[index],
        "option"
      );
    }
    // save option's price adjustments
    const savePAs = async (paArray, paName) => {
      const promises = paArray.map(async (priceAdj, i) => {
        if (priceAdj.usePA) {
          if (!priceAdj.id) {
            console.log(`create ${paName} ${i}: ${priceAdj.description}`);
            priceAdj.options_id = options[index].id;
            const record = await createRecord(
              `/price-adj/create`,
              priceAdj,
              paName
            );
          } else {
            console.log(`update price adjustment ${i}`);
            await saveRecord(
              `/price-adj/update/${priceAdj.id}`,
              priceAdj,
              paName
            );
          }
        } // delete if no longer needed
        else {
          if (priceAdj.id) {
            // only delete price adjs that are in db
            await deleteRecord(`/price-adj/delete/${priceAdj.id}`);
          }
        }
      });
      await Promise.all(promises);
    };
    await savePAs(fees, "fees");
    await savePAs(discounts, "discounts");
    // save option's warranties
    const warrantyPromises = warranties.map(async (wrnty, i) => {
      // only save warranties that are being used
      if (wrnty.useWarranty) {
        // create new warranty
        if (!wrnty.id) {
          console.log(`create warranty ${i}`);
          wrnty.options_id = options[index].id || newOption.id;
          const record = await createRecord(
            "/warranties/create",
            wrnty,
            "warranty"
          );
          console.log(`record ${i}:`, record);
          console.log("warranties:", warranties);
        } // or update existing warranty
        else {
          console.log(`update warranty ${index}`);
          await saveRecord(`/warranties/update/${wrnty.id}`, wrnty, "warranty");
        } // delete if no longer needed
      } else {
        if (wrnty.id) {
          // only delete warranties that are in db
          await deleteRecord(`/warranties/delete/${wrnty.id}`);
        }
      }
    });
    await Promise.all(warrantyPromises);
    // load records so local state is in sync with db
    loadJobOptions(job);
  }
  // --- Save Appointments ---
  else if (page === "/appointments") {
    const index = indices.appointmentsIndex;
    const appointment = appointments[index];
    const allValuesPresent = appointments.every(
      (appt) =>
        appt.core_job_data_id &&
        appt.appt_date &&
        appt.appt_time &&
        appt.appt_types_id &&
        appt.employees_id
    );
    if (!allValuesPresent) {
      displayAlert("Please include appt type, service rep, date and time...");
      return;
    }
    // Check appointments and create/update
    if (!appointment?.id) {
      const record = await createRecord(
        "/appointments/create",
        appointment,
        "option"
      );
      handleArrayChanges({
        baseName: "appointments",
        baseArray: [...appointments],
        index,
        newValues: { id: record.id },
      });
    } else {
      saveRecord(
        `/appointments/update/${appointment.id}`,
        appointment,
        "appointment"
      );
    }
    // Determine if job status
    let job_status_id;
    if (appointment.appt_types_id === 1 && appointment.appt_statuses_id === 1) {
      job_status_id = 13; // 13 - estimate - scheduled
    }
    if (appointment.appt_types_id === 1 && appointment.appt_statuses_id === 2) {
      job_status_id = 3; // 3 - estimate - closed
    }
    if (appointment.appt_types_id === 2 && appointment.appt_statuses_id === 1) {
      job_status_id = 6; // 6 - open job - scheduled
    }
    if (appointment.appt_types_id === 2 && appointment.appt_statuses_id === 2) {
      job_status_id = 14; // 14 - ready to bill
    }
    // if job status changes then update job status in state and save in db
    if (job_status_id) {
      handleChanges({
        baseName: "job",
        baseObject: { ...job },
        newValues: { job_status_id },
      });
      saveRecord(
        `/core-job/update/${job.id}`,
        { ...job, job_status_id },
        "job"
      );
    }
    // - Build Report Modal Variables -
    // Build type variable
    let jobType = " Skylight Service";
    if (parseInt(job.job_type_id) === 3) {
      jobType = " Skylight Install";
    } else if (parseInt(job.job_type_id) === 4) {
      jobType = "Commercial Skylight Project";
    }
    // const jobType = parseInt(job.job_type_id) === 1 ? "Service" : "Install";
    let reportType = appointment.appt_types_id === 1 ? "Proposal" : "Invoice";
    const type =
      appointment.appt_types_id !== 3
        ? `${jobType} ${reportType}`
        : "Warranty Checkup";
    const status = appointment.appt_statuses_id;
    const reportName = `${job.job_number} ${type}`;
    const reportDate = appointment.appt_date;
    // set type variable
    handleChanges({
      baseName: "report",
      baseObject: { ...report },
      newValues: { type, status, reportName, reportDate },
    });
    // build email variables
    const emailSubject = reportName;
    const defaultBody = defaults.defaultEmails.filter(
      (msg) => msg.appt_types_id === appointment.appt_types_id
    );
    const defaultContact = contacts.filter(
      (contact) => contact.id.toString() === job.default_contact.toString()
    );
    const defaultSignature = defaults?.defaultEmails?.filter(
      (msg) => msg.appt_types_id === 999
    );
    console.log("defaultSignature:", defaultSignature[0].text);
    let emailSignature = defaultSignature[0].text.replace(
      "Name",
      user.full_name
    );
    console.log("emailSignature1:", emailSignature);
    emailSignature = emailSignature.replace("Title", user.title);
    console.log("emailSignature2:", emailSignature);
    const body = `${defaultContact[0].contact_name},\n\n${defaultBody[0].text}\n\n${emailSignature}`;

    // // build email variables
    // const emailSubject = reportName;
    // const defaultBody = defaults.defaultEmails.filter(
    //   (msg) => msg.appt_types_id === appointment.appt_types_id
    // );
    // const defaultContact = contacts.filter(
    //   (contact) => contact.id.toString() === job.default_contact.toString()
    // );
    // const defaultSignature = defaults?.defaultEmails?.filter(
    //   (msg) => msg.appt_types_id === 999
    // );
    // const body = `${defaultContact[0].contact_name},\n\n${defaultBody[0].text}\n\n${defaultSignature[0].text}`;

    // alter variables for sandbox mode if engaged
    let emailReceivers = defaultContact[0].email;
    let sandboxEmail = user.customerEmail;
    if (user.user_mode === 1) {
      emailReceivers = sandboxEmail ? sandboxEmail : "admin@freelitetx.com";
    }
    // set email variables
    handleChanges({
      baseObject: { ...email },
      baseName: "email",
      newValues: {
        emailReceivers,
        emailSubject,
        emailBody: body,
      },
    });
    // add seconds to time string if not present
    let aptStart = appointment.appt_time;
    aptStart = aptStart?.length === 5 ? aptStart + ":00" : aptStart;

    // build appointment date variables
    const startTime = Date.parse(`${reportDate}T${aptStart}`);
    const startDate = new Date(startTime);
    const duration = (appointment.duration || 0) * 60 * 60 * 1000;
    const endTime = startTime + duration;
    const endDate = new Date(endTime);
    // format to fit what google is expecting
    const eventStart = startDate.toISOString();
    const eventEnd = endDate.toISOString();

    // build rest of appointment variables
    let eventType = "";
    if (appointment.appt_types_id === 1) {
      eventType = "Estimate";
    }
    if (appointment.appt_types_id === 2) {
      eventType = "Work";
    }
    if (appointment.appt_types_id === 3) {
      eventType = "Warranty";
    }
    if (appointment.appt_types_id === 4) {
      eventType = "Courtesy Check";
    }
    const eventTitle = `${jobType} ${eventType} ${job.job_number}`;
    const googleId = appointment.google_id;
    const { street_address, city, state, zip } = address;
    const eventAddress = `${street_address}, ${city}, ${state} ${zip}`;
    // set default attendees
    let eventAttendees = employees.filter(
      (emp) => emp.id === appointment.employees_id
    )[0].email;
    // alter variables for sandbox mode if engaged
    let sandboxCalendarEmail = user.customerEmail;
    if (user.user_mode === 1) {
      eventAttendees = sandboxCalendarEmail
        ? sandboxCalendarEmail
        : ["admin@freelitetx.com"];
    }
    // set appointment variables
    handleChanges({
      baseObject: { ...calendar },
      baseName: "calendar",
      newValues: {
        eventTitle,
        eventStart,
        eventEnd,
        eventAttendees,
        eventAddress,
        googleId,
      },
    });
    toggleReportForm();
  }
  // --- Save Payments ---
  else if (page === "/payments") {
    const index = indices.paymentsIndex;
    const payment = payments[index];
    if (!payment?.core_job_data_id) {
      displayAlert("Payment is missing core job id");
      return;
    }
    // check payments and create/update
    if (!payment?.id) {
      const record = await createRecord("/payments/create", payment, "payment");
      handleArrayChanges({
        baseName: "payments",
        baseArray: [...payments],
        index,
        newValues: { id: record.id },
      });
    } else {
      saveRecord(`/payments/update/${payment.id}`, payment, "payment");
    }
    // if job status changes then update job status in state and save in db
    let job_status_id;
    if (payment.payment_types_id === 3) {
      job_status_id = 8; // 8-completed
      handleChanges({
        baseName: "job",
        baseObject: { ...job },
        newValues: { job_status_id },
      });
      saveRecord(
        `/core-job/update/${job.id}`,
        { ...job, job_status_id },
        "job"
      );
    }
    // clear any appointment info if present
    handleChanges({
      baseName: "calendar",
      baseObject: { ...calendar },
      newValues: { ...initialState.calendar },
    });
    // - Build Report Modal Variables -
    // build type variable
    let jobType = "Skylight Service";
    if (parseInt(job.job_type_id) === 3) {
      jobType = "Skylight Install";
    } else if (parseInt(job.job_type_id) === 4) {
      jobType = "Commercial Skylight Project";
    }
    // const jobType = parseInt(job.job_type_id) === 1 ? "Service" : "Install";
    let type = `${jobType} Receipt`;
    const reportName = `${job.job_number} ${type}`;
    const reportDate = payment.payment_date;
    handleChanges({
      baseName: "report",
      baseObject: { ...report },
      newValues: { type, status: 2, reportName, reportDate },
    });
    // build email variables
    const title = reportName;
    const defaultBody = defaults.defaultEmails.filter(
      (msg) => msg.appt_types_id === 99 // in db 99 = receipt
    );
    const defaultContact = contacts.filter(
      (contact) => contact.id.toString() === job.default_contact.toString()
    );
    const defaultSignature = defaults?.defaultEmails?.filter(
      (msg) => msg.appt_types_id === 999
    );
    console.log("defaultSignature:", defaultSignature[0].text);
    let emailSignature = defaultSignature[0].text.replace(
      "Name",
      user.full_name
    );
    console.log("emailSignature1:", emailSignature);
    emailSignature = emailSignature.replace("Title", user.title);
    console.log("emailSignature2:", emailSignature);
    const body = `${defaultContact[0].contact_name},\n\n${defaultBody[0].text}\n\n${emailSignature}`;

    // alter variables for sandbox mode if engaged
    let emailReceivers = defaultContact[0].email;
    let sandboxEmail = user.customerEmail;
    if (user.user_mode === 1) {
      emailReceivers = sandboxEmail ? sandboxEmail : "admin@freelitetx.com";
    }

    // set the email variables
    handleChanges({
      baseObject: { ...email },
      baseName: "email",
      newValues: {
        emailReceivers,
        emailSubject: title,
        emailBody: body,
      },
    });
    toggleReportForm();
  } else {
    console.log("saving stuff...");
  }
};
export default appSave;
