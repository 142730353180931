import { useAppContext } from "../../context/appContext";
import { Input, InputCheckbox, InlineRadioPrice } from "../dashboard";

const AdminFeesDiscounts = () => {
  const { defaults, handleChange, saveRecord, isLoading } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    // iterate through all default priceAdjs and save each one
    defaults.defaultFees.forEach((priceAdj) => {
      saveRecord(
        `/defaults/update-pa/${priceAdj.id}`,
        priceAdj,
        "Default Fees"
      );
    });
    defaults.defaultDiscounts.forEach((priceAdj) => {
      saveRecord(
        `/defaults/update-pa/${priceAdj.id}`,
        priceAdj,
        "Default Discounts"
      );
    });
    defaults.defaultWarranties.forEach((priceAdj) => {
      saveRecord(
        `/defaults/update-warranty/${priceAdj.id}`,
        priceAdj,
        "Default Warranties"
      );
    });
  };

  const baseObject = defaults;
  const handleInput = (e, index, defaultsName, defaultsArray) => {
    const property = e.target.name;
    const newText = e.target.value;
    const value = defaultsArray.map((priceAdj, i) => {
      return i === index ? { ...priceAdj, [property]: newText } : priceAdj;
    });
    handleChange({ baseObject, name: defaultsName, value });
  };
  const handleCheck = (e, index, defaultsName, defaultsArray) => {
    const property = e.target.name;
    const checked = e.target.checked; // grab checked instead of value
    const value = defaultsArray.map((priceAdj, i) => {
      return i === index ? { ...priceAdj, [property]: checked } : priceAdj;
    });
    handleChange({ baseObject, name: defaultsName, value });
  };
  const handleRadio = (e, index, defaultsName, defaultsArray) => {
    const property = "modifier";
    const selected = e.target.value;
    const value = defaultsArray.map((priceAdj, i) => {
      return i === index ? { ...priceAdj, [property]: selected } : priceAdj;
    });
    handleChange({ baseObject, name: defaultsName, value });
  };

  return (
    <>
      {/* Default Fees */}
      <div className="group one-half">
        <form onSubmit={handleSubmit} className="form">
          <h3>Default Fees</h3>
          <div className="form-center fees">
            {defaults.defaultFees.map((fee, index) => {
              return (
                <div
                  key={index}
                  className={
                    fee.usePA
                      ? "selected fee whole flex-parent"
                      : "fee whole flex-parent"
                  }
                >
                  <div className="one-half checkbox">
                    <InputCheckbox
                      name="amount"
                      checkName="usePA"
                      value={fee.usePA}
                      textValue={fee.amount || ""}
                      handleCheckboxChange={(e) =>
                        handleCheck(
                          e,
                          index,
                          "defaults.defaultFees",
                          defaults.defaultFees
                        )
                      }
                      handleTextChange={(e) => {
                        handleInput(
                          e,
                          index,
                          "defaults.defaultFees",
                          defaults.defaultFees
                        );
                      }}
                    />
                  </div>
                  <div className="one-half checkbox radio">
                    <InlineRadioPrice
                      name={`modifier${index}`}
                      value={fee.modifier}
                      handleChange={(e) => {
                        handleRadio(
                          e,
                          index,
                          "defaults.defaultFees",
                          defaults.defaultFees
                        );
                      }}
                    />
                  </div>
                  <div className="whole no-label">
                    <Input
                      name="description"
                      labelText=""
                      value={fee.description || ""}
                      handleChange={(e) => {
                        handleInput(
                          e,
                          index,
                          "defaults.defaultFees",
                          defaults.defaultFees
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <button
              className="btn btn-block"
              type="submit"
              disabled={isLoading}
            >
              Save
            </button>
          </div>
        </form>
      </div>

      {/* Default Discounts */}
      <div className="group one-half">
        <form onSubmit={handleSubmit} className="form">
          <h3>Default Discounts</h3>
          <div className="form-center discounts">
            {defaults.defaultDiscounts.map((discount, index) => {
              return (
                <div
                  key={index}
                  className={
                    discount.usePA
                      ? "selected discount whole flex-parent"
                      : "discount whole flex-parent"
                  }
                >
                  <div className="one-half checkbox">
                    <InputCheckbox
                      name="amount"
                      checkName="usePA"
                      value={discount.usePA}
                      textValue={discount.amount || ""}
                      handleCheckboxChange={(e) =>
                        handleCheck(
                          e,
                          index,
                          "defaults.defaultDiscounts",
                          defaults.defaultDiscounts
                        )
                      }
                      handleTextChange={(e) => {
                        handleInput(
                          e,
                          index,
                          "defaults.defaultDiscounts",
                          defaults.defaultDiscounts
                        );
                      }}
                    />
                  </div>
                  <div className="one-half checkbox radio">
                    <InlineRadioPrice
                      name={`modifier${index}`}
                      value={discount.modifier}
                      handleChange={(e) => {
                        handleRadio(
                          e,
                          index,
                          "defaults.defaultDiscounts",
                          defaults.defaultDiscounts
                        );
                      }}
                    />
                  </div>
                  <div className="whole no-label">
                    <Input
                      name="description"
                      labelText=""
                      value={discount.description || ""}
                      handleChange={(e) => {
                        handleInput(
                          e,
                          index,
                          "defaults.defaultDiscounts",
                          defaults.defaultDiscounts
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <button
              className="btn btn-block"
              type="submit"
              disabled={isLoading}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      {/* Default Warranties */}
      <div className="group one-half">
        <form onSubmit={handleSubmit} className="form">
          <h3>Default Warranties</h3>
          <div className="form-center warranties">
            {/* Loop through and display the warranties */}
            {defaults.defaultWarranties?.map((wrnty, i) => {
              return (
                <div
                  key={i}
                  className={
                    wrnty.useWarranty
                      ? "selected whole checkbox"
                      : "whole checkbox"
                  }
                >
                  {/* {console.log("selectedWarranty:", wrntyIndex)} */}
                  <InputCheckbox
                    name="warranty"
                    checkName="usePA"
                    value={wrnty.useWarranty}
                    textValue={wrnty.warranty || ""}
                    handleCheckboxChange={(e) =>
                      handleCheck(
                        e,
                        i,
                        "defaults.defaultWarranties",
                        defaults.defaultWarranties
                      )
                    }
                    handleTextChange={(e) => {
                      handleInput(
                        e,
                        i,
                        "defaults.defaultWarranties",
                        defaults.defaultWarranties
                      );
                    }}
                  />
                </div>
              );
            })}
            <button
              className="btn btn-block form-row"
              type="submit"
              disabled={isLoading}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default AdminFeesDiscounts;
