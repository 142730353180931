import { useAppContext } from "../../context/appContext";
import { calcOptionTotal, calcDeposit } from "../../utils/calcOptionTotal";
import {
  TextArea,
  SimpleInputCheckbox,
  DisplayMoney,
  InlineRadioPrice,
  Checkbox,
} from "../../components/dashboard";

function WorkSpecsMain({ optionsIndex }) {
  const {
    job,
    jobItems,
    options,
    discounts,
    fees,
    handleChange,
    handleArrayChange,
    handleArrayChanges,
  } = useAppContext();

  let index = optionsIndex;

  const handleInput = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    const baseArray = [...options];
    const baseName = "options";
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  const handleJobInput = (e) => {
    handleChange({
      baseObject: { ...job },
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleCheck = (e) => {
    let deposit = e.target.value;
    let deposit_modifier = options[index]?.deposit_modifier;
    const checked = e.target.checked; // grab checked instead of value
    const baseArray = [...options];
    const baseName = "options";
    if (checked) {
      deposit = 50;
    } else {
      deposit = 0;
    }
    if (checked && deposit_modifier === null) {
      deposit_modifier = 1;
    }
    const newValues = { deposit, deposit_modifier };
    handleArrayChanges({ baseName, baseArray, index, newValues });
    // handleArrayChange({ baseName, baseArray, index, property, value });
  };

  const handleRadio = (e) => {
    const baseName = "options";
    const property = "deposit_modifier";
    const value = Number(e.target.value);
    const baseArray = options;
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  const handleTax = (e) => {
    const baseName = "options";
    const property = "sales_tax";
    const value = e.target.checked;
    const baseArray = options;
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // ----- Work Specs Main ------
  return (
    <div className="group whole">
      <form className="form" onSubmit={handleSubmit}>
        <h3>Work Specs</h3>
        <div className="form-center work-specs">
          {/* Work Description */}
          <div className="whole">
            <TextArea
              name="job.work_description"
              labelText="Work Description"
              value={job.work_description || ""}
              handleChange={(e) => handleJobInput(e)}
            />
          </div>
          {/* Sales Tax - Only for commercial jobs*/}
          {job.job_type_id === 4 && (
            <div className="one-half checkbox form-row option-total">
              <Checkbox
                name="salesTax"
                type="checkbox"
                labelText="Sales Tax"
                handleChange={handleTax}
                checked={options[index]?.sales_tax || false}
              />
            </div>
          )}
          {/* Deposit */}
          <div className="one-half form-row deposit flex-parent">
            <div className="checkbox">
              {/* combined input and checkbox */}
              <SimpleInputCheckbox
                name="deposit"
                checkName="includeDeposit"
                value={options[index]?.deposit || 0}
                textValue={options[index]?.deposit || 0}
                label=""
                handleCheckboxChange={(e) => handleCheck(e)}
                handleTextChange={(e) => handleInput(e)}
              />
            </div>
            {/* flat or percent radio buttons */}
            <div className="radio checkbox">
              <InlineRadioPrice
                name={`deposit_modifier`}
                value={options[index]?.deposit_modifier}
                handleChange={(e) => {
                  handleRadio(e);
                }}
              />
            </div>
            {/* calculate and display deposit total */}
            <div className="checkbox one-half deposit">
              <DisplayMoney
                name="depositAmount"
                value={
                  calcDeposit({
                    deposit: options[index]?.deposit,
                    deposit_modifier: options[index]?.deposit_modifier,
                    optionTotal: calcOptionTotal({
                      jobItems,
                      discounts,
                      fees,
                      sales_tax: options[index]?.sales_tax,
                    }).grandTotal,
                  }) || "$0.00"
                }
                label="Deposit Amount"
              />
            </div>
          </div>
          {/* Option Total */}
          <div className="one-half form-row option-total">
            <DisplayMoney
              name="total"
              value={
                calcOptionTotal({
                  jobItems,
                  discounts,
                  fees,
                  sales_tax: options[index]?.sales_tax,
                }).grandTotal || "$0.00"
              }
              label="Option Total"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
export default WorkSpecsMain;
