// Iterate through file numbers until a unique one is found
const iterateName = (baseName, fileList) => {
  let fileName = "";
  const nameCheck = (file) => {
    return !file.name.includes(fileName);
  };
  for (let i = 1; i <= fileList.length; i++) {
    fileName = `${baseName} ${i}`;
    if (fileList.every(nameCheck)) {
      return fileName;
    }
  }
  return `${baseName} 1`;
};

// Returns original file extension
const getExtension = (fileName) => {
  const lastPeriod = /\.[^.]*$/; // regex to find file extension
  const match = fileName.match(lastPeriod);
  return match[0];
};

export { iterateName, getExtension };
