import { useAppContext } from "../../context/appContext";
import { FaTimes } from "react-icons/fa";
import { getExtension } from "../../utils/fileNames";
import { Display, Input } from "../dashboard";

function FileModal({
  newFile,
  fileMetadata,
  setFileMetadata,
  newName,
  setNewName,
  imagePreview,
  setImagePreview,
  setShowFileModal,
}) {
  const { job, uploadFile, isLoading } = useAppContext();

  const cleanup = () => {
    // Clean up
    setFileMetadata(null);
    setNewName("");
    setImagePreview(null);
    // Close form
    setShowFileModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newPhotoNames = [];
    // Method 2 - Upload new photo & Method 3 - Take photo with camera
    const jobPath = job.folder_path;
    const fileExtension = getExtension(fileMetadata.name);
    const fileName = newName + fileExtension;
    newFile = new File([newFile], fileName, {
      type: newFile.type,
    });
    // Save file and update newPhotosNames
    console.log("newFile:", newFile);
    await uploadFile({ file: newFile, jobPath });
    newPhotoNames.push(fileName);
    // Clean up
    cleanup();
  };
  const handleCancel = (e) => {
    console.log("action cancelled...");
    cleanup();
  };

  return (
    <form className="form photo-modal">
      {/* Close Button */}
      <button className="close-btn" type="button" onClick={handleCancel}>
        <FaTimes />
      </button>
      {/* Prompt Message */}
      <div className="form-center sub-item confirm-report">
        <h3>Upload New File</h3>
        <div className="flex-parent whole">
          <div className="one-fourth image-preview">
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
            )}
          </div>
          <div className="three-fourths">
            <div className="whole">
              <Display
                name="originalName"
                label="Original File Name"
                value={fileMetadata?.name}
              />
            </div>
            <div className="whole">
              <Display
                name="fileSize"
                label="Original File Size"
                value={`${(fileMetadata?.size / 1024 / 1000).toFixed(2)} MB`}
              />
            </div>
            <div className="whole">
              <Input
                type="text"
                name="fileName"
                labelText="File Name"
                value={newName || ""}
                handleChange={(e) => setNewName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Submit/ Clear Buttons */}
      <div className="btn-container flex-parent whole">
        <div className="one-half">
          <button
            name="confirmUpload"
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "please wait..." : "confirm"}
          </button>
        </div>
        <div className="one-half">
          <button
            className="btn btn-block red"
            type="button"
            disabled={isLoading}
            onClick={handleCancel}
          >
            cancel
          </button>
        </div>
      </div>
    </form>
  );
}
export default FileModal;
