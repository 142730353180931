import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import ConfirmBox from "../ConfirmBox";
import PhotoModal from "./PhotoModal";

function Photos({ beforeAfter }) {
  const { files, photos, job, deleteRecord, handlePrimativeChange, isLoading } =
    useAppContext();

  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [selectPhoto, setSelectPhoto] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const isBefore = beforeAfter === "Before";
  const photoGroup = isBefore
    ? photos?.filter((photo) => photo?.category === "Before Photos")
    : photos?.filter((photo) => photo?.category === "After Photos");

  const handleClick = (index) => {
    setSelectPhoto(index);
  };

  const handleAddPhoto = (e) => {
    setShowPhotoModal(!showPhotoModal);
  };

  const removePhoto = async () => {
    // Remove photo from save list
    const removedPhoto = photoGroup[selectPhoto];
    const newPhotos = photos.filter(
      (photo) => photo.photo_name !== removedPhoto.photo_name
    );
    handlePrimativeChange({
      property: "photos",
      value: newPhotos,
    });
    // Remove record from database
    await deleteRecord(`/photos/delete/${removedPhoto.id}`);
    // Clean up
    setSelectPhoto(null);
  };

  const toggleRemoveBox = (id) => {
    setShowCancelModal(!showCancelModal);
  };

  // ----- Photos Section ------
  return (
    <div className="group one-half">
      <form className="form">
        <div className="form-center flex-parent">
          <h3 className="whole">{`${beforeAfter} Photos`}</h3>
          {/* Show Photos */}
          <div className="photos whole">
            {photoGroup?.map((photo, i) => {
              const photoFile = files?.filter(
                (file) => file.name === photo.photo_name
              );
              const thumbnail = photoFile[0]?.thumbnails[0]?.medium;
              return (
                <img
                  src={thumbnail?.url}
                  key={i}
                  className={
                    selectPhoto === i ? "selected-photo thumbnail" : "thumbnail"
                  }
                  height={171} // thumbnail.height
                  width={171} // thumbnail.width
                  onClick={() => handleClick(i)}
                  alt={`${photo} thumbnail`}
                />
              );
            })}
          </div>
          {/* Add Photo Button */}
          <div className="one-half">
            <button
              name={`add${beforeAfter}`}
              className="btn btn-block"
              type="button"
              onClick={handleAddPhoto}
              disabled={isLoading}
            >
              {isLoading ? "please wait..." : `Add ${beforeAfter} Photo`}
            </button>
          </div>
          {/* Remove Photo Button */}
          <div className="one-half">
            <button
              name={`deleteJob`}
              className="btn btn-block red"
              type="button"
              onClick={() => toggleRemoveBox()}
              disabled={isLoading}
            >
              Remove Photo
            </button>
          </div>
        </div>
      </form>
      {/* ----- Add Photo Modal ------ */}
      <div
        className={
          showPhotoModal ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <PhotoModal
            name="confirmRemove"
            beforeAfter={beforeAfter}
            setShowPhotoModal={setShowPhotoModal}
          />
        </div>
      </div>
      {/* ----- Remove Photo Modal ------ */}
      <div
        className={
          showCancelModal ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <ConfirmBox
            name="confirmRemove"
            confirmedFunction={removePhoto}
            message="Do you want to remove this photo?"
            setShowFunction={setShowCancelModal}
          />
        </div>
      </div>
    </div>
  );
}
export default Photos;
