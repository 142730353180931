import {
  Input,
  TextArea,
  Display,
  DisplayMoney,
  InputCheckbox,
  Select,
} from "../dashboard";
import { FaTimes, FaArrowUp, FaArrowDown } from "react-icons/fa";
import { useAppContext } from "../../context/appContext";
import Glazing from "./EditJobItem/Glazing";
import Size from "./EditJobItem/Size";
import calcJobItemTotal from "../../utils/calcJobItemTotal";
import { calcOptionTotal } from "../../utils/calcOptionTotal";

function EditJobItem({ editingItem, setEditItem, toggleEditItem }) {
  // console.log('editingItem:', editingItem)
  const {
    job,
    options,
    customer,
    fees,
    discounts,
    defaults,
    lists,
    prices,
    handleArrayChange,
    handleArrayChanges,
    createRecord,
    saveRecord,
    getArrayRecord,
    isLoading,
    displayAlert,
  } = useAppContext();

  const {
    id,
    work_types_id,
    quantity,
    total,
    work_description,
    frame_types_id,
    base_price,
    extras_price,
    unitPrice,
    custom_price,
    use_custom_price,
    optionsIndex,
    skylight_sizes_id,
    tube_depths_id,
    top_glazing_id,
    bottom_glazing_id,
    glazing_materials_id,
  } = editingItem;

  const handleInput = (e) => {
    const property = e.target.name;
    let value = e.target.value;
    console.log("value 1:", value);
    if (property === "quantity") {
      // allow only numbers
      value = value.replace(/[^0-9\s]/g, "");
      console.log("value 2:", value);
      if (value !== "") value = Number(value);
    }
    console.log("value 3:", value);
    let newEditItem = { ...editingItem, [property]: value };

    // handle adding a custom price
    const checked = e.target.checked; // grab checked instead of value
    if (property === "custom_price" && !checked) {
      newEditItem = { ...newEditItem, use_custom_price: true };
    }
    // update prices
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };
  const handleSelect = (e) => {
    const property = e.target.name;
    let value = parseInt(e.target.value);
    let newEditItem = { ...editingItem, [property]: value };
    // conditionally clear line items and reset the work description
    if (property === "frame_types_id") {
      let top_glazing = top_glazing_id;
      let bottom_glazing = bottom_glazing_id;
      let glazing_material = glazing_materials_id;
      const work_description = fillDescription({ frameValue: value });
      const extras_ids = [];
      if (value === 9 || value === 10) {
        bottom_glazing = null; // clear bottom glazing if present
        glazing_material = 2; // set glazing material to glass
        // reset top glazing color if aynthing but clear or white
        if (top_glazing !== 3 && top_glazing !== 1) {
          top_glazing = null;
        }
      }
      newEditItem = {
        ...newEditItem,
        work_description,
        extras_ids,
        top_glazing_id: top_glazing,
        bottom_glazing_id: bottom_glazing,
        glazing_materials_id: glazing_material,
      };
    }
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };
  const handleCheck = (e) => {
    const checked = e.target.checked; // grab checked instead of value
    const newEditItem = { ...editingItem, use_custom_price: checked };
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };
  const qtyChange = (change) => {
    const value = quantity + change;
    const newEditItem = { ...editingItem, quantity: value };
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };
  const saveJobItem = async () => {
    // console.log("saving editingItem:", editingItem);
    // --- Require certain fields to submit (based on work type) ---
    // skylight replacements and new installs
    if (work_types_id === 1 || work_types_id === 14) {
      if (
        !skylight_sizes_id ||
        !frame_types_id ||
        !top_glazing_id ||
        !glazing_materials_id
      ) {
        const alertMessage = `Please include: ${
          !skylight_sizes_id ? "size, " : ""
        }${!frame_types_id ? "frame, " : ""}${
          !glazing_materials_id ? "glazing type, " : ""
        }${!top_glazing_id ? "glazing color" : ""}`;
        displayAlert(alertMessage);
        return;
      }
    }
    // reflash
    if (work_types_id === 4) {
      if (!skylight_sizes_id) {
        displayAlert("Please include size");
        return;
      }
    }
    // sun tunnel
    if (work_types_id === 5) {
      if (
        !skylight_sizes_id ||
        tube_depths_id === null ||
        tube_depths_id === undefined
      ) {
        displayAlert("Please include diameter and tube depth");
        return;
      }
    }
    // Ensure a corresponding option exists
    let newOption;
    if (!options[optionsIndex]?.id) {
      // if not then create one
      newOption = await createRecord(
        "/options/create",
        options[optionsIndex],
        "option"
      );
      handleArrayChange({
        baseName: "options",
        baseArray: [...options],
        index: optionsIndex,
        property: "id",
        value: newOption.id,
      });
    }
    // adjust custom price
    let new_custom_price = custom_price;
    if (!use_custom_price) {
      new_custom_price = null;
    }
    // update existing job item
    if (id) {
      console.log("updating jobitem...");
      await saveRecord(
        `/job-items/update/${id}`,
        {
          ...editingItem,
          options_id: options[optionsIndex]?.id || newOption.id,
          custom_price: new_custom_price,
        },
        "job item"
      );
      // or create new job item
    } else {
      console.log("creating jobitem...");
      await createRecord(
        `/job-items/create`,
        {
          ...editingItem,
          options_id: options[optionsIndex]?.id || newOption.id,
          custom_price: new_custom_price,
        },
        "job item"
      );
    }
    const newJobItems = await getArrayRecord(
      `/job-items/${options[optionsIndex]?.id || newOption.id}`,
      "jobItems"
    );
    // update option total and save option with new total
    const optionTotal = calcOptionTotal({
      jobItems: newJobItems,
      discounts,
      fees,
    }).grandTotal;
    // also update id if it's missing
    const optId = options[optionsIndex]?.id || newOption.id;
    handleArrayChanges({
      baseName: "options",
      baseArray: [...options],
      index: optionsIndex,
      newValues: { total: optionTotal, id: optId },
    });
    saveRecord(`/options/update/${optId}`, options[optionsIndex], "option");
    // finally, toggle the modal window
    toggleEditItem();
  };
  const fillDescription = ({ workValue, frameValue }) => {
    let description = defaults.defaultWorkDescs.filter(
      (desc) => desc.work_types_id === (workValue || work_types_id)
    );
    if (description.length > 1 && frameValue) {
      description = description.filter((desc) =>
        desc.frame_types.includes(frameValue)
      );
    }
    return description[0]?.text || "";
  };
  const handleWorkChange = (e) => {
    const workValue = parseInt(e.target.value);
    const description = fillDescription({ workValue });
    const frame_type = workValue === 5 ? "5" : null;
    // reset editing values so no hidden data lingers
    const newEditItem = {
      ...editingItem,
      work_types_id: workValue,
      work_description: description,
      frame_types_id: frame_type,
      extras_ids: [],
      top_glazing_id: null,
      bottom_glazing_id: null,
      tube_depths_id: null,
      measurement_types_id: null,
      exact_width: null,
      exact_length: null,
      glazing_materials_id: null,
      skylight_sizes_id: null,
    };
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };
  // console.log("custom_price-MAIN:", custom_price);
  return (
    <form name="edit-job-item" className="form edit-job-item">
      <button className="close-btn" type="button" onClick={toggleEditItem}>
        <FaTimes />
      </button>
      <div className="form-center sub-item">
        {/* ----- SECTION 1 ----- */}
        <div className="one-third flex-parent">
          {/* Job Type buttons */}
          <div className="whole">
            <Select
              name="work_types_id"
              labelText="Work Type"
              handleChange={(e) => handleWorkChange(e)}
              value={work_types_id || "default"}
              options={
                lists &&
                lists.listWorkTypes?.filter((item) =>
                  item.job_type_id.includes(job?.job_type_id)
                )
              }
            />
          </div>
          {/* Quantity */}
          <div className="two-thirds">
            <div className="sub-section">
              <Input
                name="quantity"
                // type="number"
                inputMode="numeric"
                value={quantity || ""}
                handleChange={(e) => handleInput(e)}
              />
            </div>
          </div>
          <div className="one-third quantity">
            <button
              name="quantityUp"
              className="btn quantity"
              type="button"
              onClick={() => qtyChange(1)}
            >
              <FaArrowUp />
            </button>
            <button
              name="quantityDown"
              className="btn quantity"
              type="button"
              onClick={() => {
                if (quantity > 1) qtyChange(-1);
              }}
            >
              <FaArrowDown />
            </button>
          </div>
          {/* Frame Type (not on sun tunnel or labor work types and not for commercial jobs)*/}
          {work_types_id !== 5 &&
            work_types_id !== 13 &&
            job.job_type_id !== 4 && (
              <div className="whole">
                <Select
                  name="frame_types_id"
                  labelText="Frame Type"
                  handleChange={(e) => handleSelect(e)}
                  value={frame_types_id}
                  options={
                    lists &&
                    lists.listFrameTypes?.filter(
                      (item) => item.skylight_style === "standard"
                    )
                  }
                />
              </div>
            )}
        </div>
        {/* ----- SECTION 2 ----- */}
        <Glazing editingItem={editingItem} setEditItem={setEditItem} />
        {/* ----- SECTION 3 ----- */}
        <Size editingItem={editingItem} setEditItem={setEditItem} />
        {/* ----- Bottom Section ----- */}
        {/* Work Description */}
        <div className="two-thirds">
          <TextArea
            name="work_description"
            value={work_description || ""}
            labelText="Work Description"
            handleChange={(e) =>
              setEditItem({
                ...editingItem,
                work_description: e.target.value,
              })
            }
          />
        </div>
        <div className="one-third">
          {/* Price Summary */}
          <DisplayMoney
            name="Base Price"
            value={use_custom_price ? "custom" : base_price}
          />
          <DisplayMoney
            name="Line Items"
            value={use_custom_price ? "custom" : extras_price}
          />
          <DisplayMoney
            name="Unit Price"
            value={use_custom_price ? custom_price : unitPrice}
          />
          <Display name="Quantity" value={quantity} />
          <div className="bold">
            <DisplayMoney name="Total" value={total || "custom"} />
          </div>
          {/* Custom Price */}
          <div className="whole checkbox">
            <InputCheckbox
              name="custom_price"
              value={use_custom_price}
              textValue={use_custom_price ? custom_price : ""}
              label="Custom Price"
              handleCheckboxChange={(e) => {
                handleCheck(e);
              }}
              handleTextChange={(e) => {
                handleInput(e);
              }}
            />
          </div>
        </div>
        {/* Submit/ Clear Buttons */}

        <div className="one-half">
          <button
            name="save_job_item"
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={saveJobItem}
          >
            {isLoading ? "please wait..." : "save"}
          </button>
        </div>
        <div className="one-half">
          <button
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={toggleEditItem}
          >
            cancel
          </button>
        </div>
      </div>
    </form>
  );
}
export default EditJobItem;
