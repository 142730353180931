import { Select, CheckboxButton, RadioPickButton } from "../../dashboard";
import { useAppContext } from "../../../context/appContext";
import calcJobItemTotal from "../../../utils/calcJobItemTotal";

const Glazing = ({ editingItem, setEditItem }) => {
  // console.log('Glazing editingItem:', editingItem)
  const { job, customer, prices, lists } = useAppContext();
  const {
    work_types_id,
    top_glazing_id,
    bottom_glazing_id,
    glazing_materials_id,
    extras_ids,
    frame_types_id,
  } = editingItem;

  const veluxGlazingTypes = [
    { id: 3, text: "Clear Glass" },
    { id: 1, text: "White Glass" },
  ];
  const sunTunnelFrameTypes = [
    { id: 5, text: "Standard" },
    { id: 6, text: "Low Profile" },
    { id: 7, text: "Impact Dome" },
  ];

  const handleCheckBox = (key) => {
    // const value = e.target.checked; // grab checked instead of value
    let newIds = [...extras_ids];
    const value = !extras_ids?.includes(key);
    if (value) {
      newIds.push(key);
    } else {
      newIds = newIds.filter((item) => item !== key);
    }
    const newEditItem = { ...editingItem, extras_ids: newIds };
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };

  const handleInput = (e) => {
    const inputName = e.target.name;
    const inputValue = parseInt(e.target.value);
    console.log("inputName:", inputName);
    console.log("inputValue:", inputValue);
    // remove extras for white velux deckmount skylights
    let newExtras = [...extras_ids];
    if (
      frame_types_id === 10 &&
      inputName === "top_glazing_id" &&
      inputValue === 1
    ) {
      newExtras = [];
    }
    const newEditItem = {
      ...editingItem,
      extras_ids: newExtras,
      [inputName]: inputValue,
    };
    // add the extra step of a pricing update for relevant fields
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };

  return (
    <>
      <div className="one-third flex-parent">
        {/* ----- Glazing Options for everything but Velux replacements, Sun Tunnels and Labor ------ */}
        {job.job_type_id !== 4 && // not needed for commercial work
          work_types_id !== 3 && // not a replacement
          work_types_id !== 5 && // not a sun tunnel
          work_types_id !== 13 && // not additional labor
          frame_types_id !== 9 && // not a VELUX curb mount nor deck mount
          frame_types_id !== 10 && (
            <>
              {/* Top Glazing Color */}
              <div className="one-half">
                <Select
                  type="text"
                  name="top_glazing_id"
                  labelText="Top Glazing"
                  value={top_glazing_id}
                  handleChange={(e) => handleInput(e)}
                  options={lists && lists.listGlazingColors}
                />
              </div>
              {/* Bottom Glazing Color */}
              <div className="one-half">
                <Select
                  type="text"
                  name="bottom_glazing_id"
                  labelText="Bottom Glazing"
                  value={bottom_glazing_id}
                  handleChange={(e) => handleInput(e)}
                  options={lists && lists.listGlazingColors}
                />
              </div>
              {/* Glazing Type */}
              <div className="whole">
                <Select
                  type="text"
                  name="glazing_materials_id"
                  labelText="Glazing Material"
                  value={glazing_materials_id}
                  handleChange={(e) => handleInput(e)}
                  options={lists && lists.listGlazingMaterials}
                />
              </div>
              {/* Custom Flashing Option */}
              {/* <div className="form-row whole">
                <CheckboxButton
                  type="checkbox"
                  name="customFlashing"
                  labelText="Custom Formed Flashing"
                  value={"on"}
                  checked={extras_ids?.includes(9)}
                  handleChange={() => handleCheckBox(9)}
                />
              </div> */}
            </>
          )}

        {/* Display only if Velux Curb or Deck Mount */}
        {(frame_types_id === 9 || frame_types_id === 10) && (
          <>
            {/* Glazing Color */}
            <div className="whole">
              <RadioPickButton
                name="top_glazing_id"
                labelText="Glazing Color"
                handleChange={(e) => handleInput(e)}
                value={editingItem?.top_glazing_id || "default"}
                options={veluxGlazingTypes}
                buttonClasses="one-half"
              />
            </div>
            {/* Blinds Option */}
            {/* velux curb mount replacements*/}
            {(work_types_id === 1 || work_types_id === 4) &&
              frame_types_id === 9 && (
                <div className="form-row whole">
                  <CheckboxButton
                    type="checkbox"
                    name="veluxBlinds"
                    labelText="Solar Powered Blinds CM"
                    value={"on"}
                    checked={extras_ids?.includes(1)}
                    handleChange={() => handleCheckBox(1)}
                  />
                </div>
              )}
            {/* Only for deckmount reflashes and replacements with clear glazing*/}
            {(work_types_id === 1 || work_types_id === 4) &&
              frame_types_id === 10 &&
              top_glazing_id !== 1 && (
                <div className="form-row whole">
                  <CheckboxButton
                    type="checkbox"
                    name="veluxBlinds"
                    labelText="Solar Powered Blinds DM"
                    value={"on"}
                    checked={extras_ids?.includes(10)}
                    handleChange={() => handleCheckBox(10)}
                  />
                </div>
              )}
            {/* Velux Flashing Option */}
            {/* only display for curbmount frame type */}
            {work_types_id === 4 && frame_types_id === 9 && (
              <div className="form-row whole">
                <CheckboxButton
                  type="checkbox"
                  name="veluxFlashing"
                  labelText="Velux Flashing Kit"
                  value={"on"}
                  checked={extras_ids?.includes(2)}
                  handleChange={() => handleCheckBox(2)}
                />
              </div>
            )}
          </>
        )}
        {/* Display only if Sun Tunnel*/}
        {work_types_id === 5 && (
          <>
            {/* Frame Type */}
            <div className="whole">
              <RadioPickButton
                name="frame_types_id"
                labelText="Skylight Style"
                handleChange={(e) => handleInput(e)}
                value={editingItem?.frame_types_id || "default"}
                options={sunTunnelFrameTypes}
                buttonClasses="one-third"
              />
            </div>
            {/* Solar Light Option */}
            <div className="checkbox checkbox-button whole">
              <CheckboxButton
                type="checkbox"
                name="solar_powered_light"
                labelText="Solar Powered Light"
                value={"on"}
                checked={extras_ids?.includes(3)}
                handleChange={() => handleCheckBox(3)}
              />
            </div>
            {/* Universal Night Light Option */}
            <div className="checkbox checkbox-button whole">
              <CheckboxButton
                type="checkbox"
                name="universal_light_kit"
                labelText="Universal Light Kit"
                value={"on"}
                checked={extras_ids?.includes(4)}
                handleChange={() => handleCheckBox(4)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Glazing;
