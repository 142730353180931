import { Display, Input } from "../../dashboard";

function FileUpload({ imagePreview, fileMetadata, newName, setNewName }) {
  return (
    <div className="flex-parent whole">
      <div className="one-fourth image-preview">
        {imagePreview && (
          <img
            src={imagePreview}
            alt="Preview"
            style={{ maxWidth: "200px", maxHeight: "200px" }}
          />
        )}
      </div>
      <div className="three-fourths">
        <div className="whole">
          <Display
            name="originalName"
            label="Original File Name"
            value={fileMetadata?.name}
          />
        </div>
        <div className="whole">
          <Display
            name="fileSize"
            label="Original File Size"
            value={`${(fileMetadata?.size / 1024 / 1000).toFixed(2)} MB`}
          />
        </div>
        <div className="whole">
          <Input
            type="text"
            name="fileName"
            labelText="File Name"
            value={newName || ""}
            handleChange={(e) => setNewName(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
export default FileUpload;
