import ReportsJobItems from "./ReportsJobItems";
import { DisplayMoney } from "../dashboard";
import { calcOptionTotal, calcDeposit } from "../../utils/calcOptionTotal";
import { useAppContext } from "../../context/appContext";
function ReportsOptionData({ optionVars, reportName }) {
  const { payments, lists } = useAppContext();
  const { jbItms, wrrnts, dscnts, fs, optn, slsTx } = optionVars || {};

  const totals = calcOptionTotal({
    jobItems: jbItms,
    discounts: dscnts,
    fees: fs,
    sales_tax: slsTx,
  });
  const { jobsTotal, grandTotal, subTotal, salesTax } = totals; // priceAdjs,

  // console.log("optionsId:", optionsId);
  // console.log("optionVars:", optionVars);
  // console.log("grandTotal:", grandTotal);
  // console.log("dscnts:", dscnts);
  // console.log("fs:", fs);

  const getDeposit = () => {
    // console.log("index:", index);
    const deposit = optn.deposit;
    const deposit_modifier = optn.deposit_modifier;

    return calcDeposit({ deposit, deposit_modifier, optionTotal: grandTotal });
  };
  const getDue = () => {
    const totalPaid = payments.reduce((ttl, payment) => {
      if (payment.amount) {
        return ttl + Number(payment.amount?.slice(1).replaceAll(",", ""));
      }
      return ttl;
    }, 0);
    // return grandTotal - totalPaid;
    return grandTotal - totalPaid;
  };

  return (
    <>
      {/* ----- Job Items ----- */}
      <ReportsJobItems jbItms={jbItms} key={optn.id} />
      {/* ----- Warranties ----- */}
      {wrrnts?.length > 0 && (
        <div className="whole section">
          <h3>Warranties</h3>
          {wrrnts.map((wrnty) => {
            return (
              <div className="form-row display" key={wrnty.id}>
                {wrnty.warranty}
              </div>
            );
          })}
        </div>
      )}
      {/* ----- Price Summary ----- */}
      <div className="price-summary whole section">
        <h3>Price Summary</h3>
        <div className="group">
          {/* Job Items Total */}
          <DisplayMoney name="Job Items Total" value={jobsTotal} />
          {/* List Price Adjustments */}
          {dscnts?.map((discount, index) => {
            const { amount, description, modifier, usePA } = discount;
            const ttl = modifier === 1 ? (amount / 100) * jobsTotal : amount;
            if (usePA) {
              return (
                <DisplayMoney name={description} value={ttl} key={index} />
              );
            }
            return null;
          })}
          {fs?.map((fee, index) => {
            const { amount, description, modifier, usePA } = fee;
            const ttl = modifier === 1 ? (amount / 100) * jobsTotal : amount;
            if (usePA) {
              return (
                <DisplayMoney name={description} value={ttl} key={index} />
              );
            }
            return null;
          })}
          {/* Tax and Subtotal if relevant */}
          {salesTax && (
            <>
              <DisplayMoney name="Subtotal" value={subTotal} />
              <DisplayMoney name="Sales Tax" value={salesTax} />
            </>
          )}
          {/* Grand Total */}
          <div className="bold">
            <DisplayMoney name={"Grand Total"} value={grandTotal} />
          </div>
          {/* Deposit */}
          <DisplayMoney
            name="Required Deposit (nonrefundable)"
            value={getDeposit()}
          />
          {/* List Payments */}
          {payments?.map((payment, index) => {
            const { payment_types_id, amount, payment_date } = payment;
            const text = lists?.listPaymentTypes?.filter(
              (item) => item.id === payment_types_id
            )[0].text;
            const baseDate = new Date(payment_date);
            const timeZoneOffset = baseDate.getTimezoneOffset() * 60 * 1000;
            const adjustedTimestamp = Date.parse(baseDate) + timeZoneOffset;
            const payDate = new Date(adjustedTimestamp);
            const name = `${text} (${payDate.toLocaleDateString()})`;
            return <DisplayMoney name={name} value={amount} key={index} />;
          })}
          {/* Remaining Balance */}
          {!reportName.includes("Proposal") ? (
            <div className="bold">
              <DisplayMoney name="Remaining Balance" value={getDue()} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default ReportsOptionData;
