import { useAppContext } from "../../../context/appContext";

function AddMethodButton({
  accept,
  addMethod,
  buttonLabel,
  capture,
  handleFileInput,
  inputType,
  method,
  setAddMethod,
}) {
  const { isLoading } = useAppContext();

  return (
    <div className="one-fourth">
      <label className="file-upload btn-block">
        <span
          className={
            addMethod === method
              ? "selected btn label-button"
              : "btn label-button"
          }
        >
          {isLoading ? "please wait..." : buttonLabel}
        </span>
        <input
          name="file"
          accept={accept}
          capture={capture}
          className="btn btn-block"
          onChange={handleFileInput}
          onClick={() => setAddMethod(method)}
          type={inputType}
        />
      </label>
    </div>
  );
}
export default AddMethodButton;
