import { useAppContext } from "../context/appContext";
import { FaTimes } from "react-icons/fa";

function ConfirmBox({ name, confirmedFunction, message, setShowFunction }) {
  const { isLoading, toggleConfirmBox } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    // perform whatever action is in question and close form
    if (setShowFunction) {
      confirmedFunction();
      setShowFunction(false);
    } else {
      toggleConfirmBox({ confirmed: true, confirmedFunction });
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    console.log("action cancelled...");
    // close form
    if (setShowFunction) {
      setShowFunction(false);
    } else {
      toggleConfirmBox({ confirmed: false, confirmedFunction });
    }
  };

  return (
    <form className="form email-report-form">
      {/* Close Button */}
      <button className="close-btn" type="button" onClick={handleCancel}>
        <FaTimes />
      </button>
      {/* Prompt Message */}
      <div className="form-center sub-item confirm-report">
        <div className="">
          <h3>{message}</h3>
        </div>
      </div>
      {/* Submit/ Clear Buttons */}
      <div className="btn-container flex-parent whole">
        <div className="one-half">
          <button
            name={name}
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "please wait..." : "confirm"}
          </button>
        </div>
        <div className="one-half">
          <button
            className="btn btn-block red"
            type="button"
            disabled={isLoading}
            onClick={handleCancel}
          >
            cancel
          </button>
        </div>
      </div>
    </form>
  );
}
export default ConfirmBox;

// --- Usage Template ---

{
  /* <div
  className={
    showConfirmBox ? "show-confirm-box modal-container" : "modal-container"
  }
>
  <div className="group">
    <ConfirmBox
      confirmedFunction={console.log("you better delete that thing...")}
      toggleBox={toggleConfirmBox}
    />
  </div>
</div>; */
}
